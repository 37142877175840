<template>
  <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
      <div class="col-12 mt-5 xl:mt-0 text-center">
        <img alt="Logo" :src="logo()" class="mb-5" height="36" />
      </div>
      <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(33, 150, 243, 0.4) 10%, rgba(33, 150, 243, 0) 30%);">
        <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
          <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">Welcome to {{ appName }}!</div>
          </div>
          <div class="w-full md:w-10 mx-auto">
            <label for="email1" class="block text-900 text-xl font-medium mb-2">E-mail</label>
            <InputText id="email1" v-model="form.email" type="text" class="w-full mb-3" placeholder="E-mail" style="padding:1rem;" />

            <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
            <Password id="password1" v-model="form.password" placeholder="Password" :feedback="false" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>

            <Button @click.prevent="signIn" label="Log in" class="w-full p-3 text-xl mt-5"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

import ApiService from "./services/apiService"
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Button from 'primevue/button'

const appName = ref("")

const form = ref({
  email: '',
  password: '',
  rememberMe: false
})

const logo = () => {
  return new URL(`/images/logo2.png`, import.meta.url).href
}

const signIn = async () => {
  const { data } = await ApiService.post('/admin_users/sign_in', {
    admin_user: {
      email: form.value.email,
      password: form.value.password,
    }
  })
  window.location.href = data.redirectPath
}

onMounted(() => {
  appName.value = document.body.dataset.appName
})
</script>
